<template>
  <div
    class="bg-white w-100 d-flex justify-content-center align-items-center d-flex flex-column"
    style="height: 100vh"
  >
    <div class="font-weight-bolder mb-4" style="font-size: 32px">NOT FOUND</div>
    <router-link
      to="/"
      class="btn-contact text-white"
      style="
        white-space: nowrap;
        width: fit-content;
        padding: 8px 20px;
        font-size: inherit;
      "
    >
      Back home
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Projects',
  data() {
    return {};
  },

  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  watch: {
    lang() {},
  },
  methods: {},
};
</script>

<style>
.btn-contact {
  display: block;
  text-decoration: none;
  border: none;
  border-radius: 100px;
  background: #fc7735;
  padding: 8px 28px;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}
</style>
